@import 'Colors.scss';


#feast23-container{
    font-family: 'Ubuntu', sans-serif;
    width:100%;
    padding: 0 calc(10% - 0.6em) 0 10%;
    height:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: $menuLettersStrong;
    overflow-y: auto;
    scrollbar-color: $menuLetters $mainBeige;
    scrollbar-width: auto;
    margin-bottom: 1em;
    font-weight:500;
    background-image: url('../Images/backgrounds/feast_back.png');
    background-position-x: 0;
    background-position-y: 0;
    background-repeat: no-repeat;
    background-color: rgba(white,0.4);
}

#feast23-container span:first-of-type{
    text-align: center;
    font-weight: bold;
    margin: 2em 0 2em 0;
}

.feast-text-container{
    width:90%;
    text-align: justify;
    margin-bottom:1em;
}

#feast23-video{
    margin-top:2em;
    border:none;
    width:420px;
    height:200px;
    max-width: 80vw;
    border-radius: 20px;
    overflow:visible!important;
}